import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';

function Layout() {
  const apiSiteURL = process.env.REACT_APP_CMS_API;
  const [Data, setData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const api_url = apiSiteURL + `/menu/main?_format=json`;
  const footer_api_url = apiSiteURL + `/menu/footer?_format=json`;

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(api_url);
        if (typeof res?.data == "object") {
          setData(res.data);
        } else {
          setData(null);
        }
        return res;
      } catch (error) {
      }
    };

    const getFooterData = async () => {
      try {
        const res = await axios.get(footer_api_url);
        if (typeof res?.data == "object") {
          setFooterData(res.data);
        } else {
          setFooterData(null);
        }
        return res;
      } catch (error) {
      }
    };
    getData();
    getFooterData();
  }, [api_url, footer_api_url]);

  return (
    <>
      <Header data={Data} />
      <Footer data={footerData} />
    </>
  )
}

export default Layout;
